<template>
  <div>
    <v-row v-if="mergeErrorsTotalItems > 0">
      <v-col cols="12">
        <v-card>
          <div class="pa-4 d-flex flex-column w-full h-full">
            <div class="d-flex align-center">
              <span class="text-h6 font-weight-bold">
                {{ mergeErrorsTotalItems }} Merge errors
              </span>

              <v-spacer />

              <v-btn
                color="primary"
                outlined
                :to="{ name: 'root/system-performance/merge-errors-list' }"
              >
                <span class="text-uppercase">Resolve</span>
                <v-icon right>$mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="6" lg="4">
        <DuplicatedSetsByObjectCard id="dupObjectTile" />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <ChartDoughnutCard
          id="mergeDuplicateSetsTile"
          :is-loading="isLoadingMergedDupeSetsStats"
          title="Merged Duplicate Sets"
          title-tooltip="Merged duplicate sets statistics by role"
          :items="mergedDupeSetsStatsByRole"
          :description="`${mergedDupeSetsCount} Duplicate sets`"
          :to="{ name: 'root/system-performance/merged-dupe-sets' }"
        />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <ChartDoughnutCard
          :is-loading="isLoadingJobsStats"
          title="Jobs"
          :items="jobsStats"
          :description="`${jobsCount} Jobs`"
          :to="{ name: 'root/system-performance/jobs' }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import DuplicatedSetsByObjectCard from './components/DuplicatedSetsByObjectCard'
import ChartDoughnutCard from '@/components/common/ChartDoughnutCard'

export default {
  components: {
    DuplicatedSetsByObjectCard,
    ChartDoughnutCard,
  },
  data() {
    return {
      isLoadingMergedDupeSetsStats: false,
      isLoadingJobsStats: false,
    }
  },
  computed: {
    ...mapState('dashboard', ['jobsStats', 'mergeErrorsTotalItems']),
    ...mapGetters('dashboard', [
      'mergedDupeSetsStatsByRole',
      'mergedDupeSetsCount',
      'jobsCount',
    ]),
  },
  mounted() {
    this.fetchMergedDupeSetsStats()
    this.fetchJobsStats()
    this.getMergeErrors()
  },
  methods: {
    ...mapActions('dashboard', [
      'getMergedDupeSetsStats',
      'getJobsStats',
      'getMergeErrors',
    ]),
    async fetchMergedDupeSetsStats({ start, end } = {}) {
      this.isLoadingMergedDupeSetsStats = true
      await this.getMergedDupeSetsStats({ start, end })
      this.isLoadingMergedDupeSetsStats = false
    },
    async fetchJobsStats() {
      this.isLoadingJobsStats = true
      await this.getJobsStats()
      this.isLoadingJobsStats = false
    },
  },
}
</script>
