<template>
  <canvas ref="chart" />
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  LineController,
  LinearScale,
  CategoryScale,
  Tooltip,
} from 'chart.js'

Chart.register(
  BarController,
  LineController,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip
)

export default {
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {
    new Chart(this.$refs.chart, {
      type: 'bar',
      data: this.chartData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    })
  },
}
</script>
