<template>
  <ChartCard
    :is-loading="isLoading"
    :title="$t('duplicate_sets_by_object')"
    :title-tooltip="$t('duplicate_sets_by_object')"
    :description="`${dupeSetsToReviewCount} Duplicate sets`"
  >
    <ChartBar v-if="chartData" :chart-data="chartData" />
  </ChartCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChartBar from '@/components/common/ChartBar'
import ChartCard from '@/components/common/ChartCard'

export default {
  components: {
    ChartBar,
    ChartCard,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters('dashboard', [
      'dupeSetsToReviewCount',
      'dupeSetsToReviewStatsByEntity',
    ]),
    chartData() {
      if (this.dupeSetsToReviewStatsByEntity.length > 0) {
        const labels = []
        const data = []
        const backgroundColor = []

        for (
          let i = 0, len = this.dupeSetsToReviewStatsByEntity.length;
          i < len;
          i++
        ) {
          const stat = this.dupeSetsToReviewStatsByEntity[i]

          labels.push(stat.label)
          data.push(stat.count)
          backgroundColor.push(stat.color)
        }

        return {
          labels,
          datasets: [
            {
              data,
              maxBarThickness: 60,
              backgroundColor,
            },
          ],
        }
      }
      return null
    },
  },
  async created() {
    this.isLoading = true
    await this.getDupeSetsToReviewStats()
    this.isLoading = false
  },
  methods: {
    ...mapActions('dashboard', ['getDupeSetsToReviewStats']),
  },
}
</script>
